<template>
  <div class="login h-screen">
    <div
      class="container mx-auto h-full flex flex-col justify-center items-center xl:block xl:justify-center xl:items-center"
    >
      <div
        class="flex justify-center xl:justify-start xl:absolute"
      >
        <router-link
          to="/"
          class="-intro-x flex items-center pt-5"
          href=""
        >
          <img
            alt="Quant Lines"
            class="w-20"
            src="/images/logo-with-text-light.svg"
          >
        </router-link>
      </div>
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <slot name="leftContent" />
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Right Content -->
        <div class="xl:h-auto flex py-5 xl:py-0 xl:my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none xl:w-auto"
          >
            <slot name="rightContent" />
          </div>
        </div>
        <!-- END: Right Content -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
};
</script>

<style scoped></style>
